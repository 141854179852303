import React, { useEffect, useState } from 'react'

import { Button, Circle, Input, Search, Summary, SummaryCol, SummaryDetails } from './style'

import { useLocation } from '@reach/router'
import slugify from 'slugify'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

type FaqProps = {
  color?: string;
  questionColor?: string;
  answersColor?: string;
  answerData: answerDataProps[];
  columns?: ColumnsProps;
  searchBg?: string;
  id?: string;
  className?: string;
  borderColor?: string;
  placeholderColor?: string;
  inputPlaceholder?: string;
  chevronBgColor?: string;
  chevronColor?: string;
}

type answerDataProps = {
  question: string;
  answer: string;
  active?: boolean;
}

type ColumnsProps = {
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const defaultColors = {
  sm: 1,
  md: 1,
  lg: 2,
  xl: 2,
}

const FAQ = ({ color, questionColor, answersColor, answerData, columns, searchBg, id, className, borderColor, placeholderColor, inputPlaceholder, chevronBgColor, chevronColor }: FaqProps) => {
  const location = useLocation()
  const [ faqList, setFaqList ] = useState(answerData)
  const [ searchText, setSearchText ] = useState('')
  const cols = {
    ...defaultColors,
    ...columns,
  }

  const isFoundActive = (slug: string): boolean => {
    const hash = location.hash.substr(1)
    return hash === slug
  }

  useEffect(() => {
    const newList = faqList.map((item: answerDataProps) => {
      return {
        ...item,
        active: isFoundActive(slugify(item.question)),
      }
    })
    setFaqList(newList)
  }, [])

  const handleClick = (index: number) => {
    const newList = faqList.map((item: answerDataProps, idx: number) => {
      if (idx === index) {
        return {
          ...item,
          active: !item.active,
        }
      }
      return item
    })
    setFaqList(newList)
  }

  const handlClean = () => {
    const newList = answerData.map((item: answerDataProps) => {
      return item
    })
    setFaqList(newList)
    setSearchText('')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueInput = event.target.value
    setSearchText(valueInput)
    if (valueInput.length >= 3) {
      const valueSplit = valueInput.trim().split(/\s/)
      const valueRegex = new RegExp(`(${valueSplit.join('|')})`, 'gi')
      const filter = faqList.filter((item: answerDataProps) => item.question.match(valueRegex) || item.answer.match(valueRegex))
        .map((item: answerDataProps) => ({
          active: true,
          question: item.question,
          answer: item.answer.replace(/<[^>]*>/g, '').replace(valueRegex, '<strong class="text-orange--extra">$1</strong>'),
        }))
      setFaqList([ ...filter ])
    } else {
      setFaqList(answerData)
    }
  }

  return (
    <div className={`col-12 ${className}`} id={id}>
      <Search>
        <Input
          type='text'
          placeholder={inputPlaceholder}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)} value={searchText}
          background={searchBg}
          placeholderColor={placeholderColor}
        />
        {
          searchText === '' ? (
            <OrangeDsIcon icon='search' size='MD' color={color || '#ff7a00'} className='search-icon' />

          ) : (
            <Button onClick={() => handlClean()}>
              <OrangeDsIcon icon='exit' size='MD' color='#ff7a00' />
            </Button>
          )
        }
      </Search>
      <div className='row'>
        {
          faqList.length > 0
            ? faqList.map((item: answerDataProps, index: number) => (
              <SummaryCol
                className={`col-12 col-sm-${12 / cols.sm} col-md-${12 / cols.md} col-lg-${12 / cols.lg} col-xl-${12 / cols.xl} `}
                key={item.question}
                id={slugify(item.question)}
              >
                <Summary borderColor={borderColor} active={item.active}>
                  <div className='header' onClick={() => handleClick(index)}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h3
                        className={`fs-14 lh-17 fs-lg-16 lh-lg-19 pr-4 mb-0 ${questionColor || 'text-grayscale--500'} fw-600`}
                        dangerouslySetInnerHTML={{ __html: item.question }}
                      />
                      <Circle background={chevronBgColor}>
                        {
                          item.active ? <OrangeDsIcon icon='chevron-up' color={chevronColor} size='MD' /> : (
                            <OrangeDsIcon icon='chevron-down' color={chevronColor} size='MD' />
                          )
                        }
                      </Circle>
                    </div>
                  </div>
                  {
                    item.active && (
                      <SummaryDetails>
                        <div
                          className={`fs-14 lh-17 ${answersColor || 'text-grayscale--400'} `}
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        />
                      </SummaryDetails>
                    )
                  }
                </Summary>
              </SummaryCol>

            )) : (
              <div className='col-12'>
                <p className={`fs-14 lh-17 fs-lg-16 lh-lg-19 ${questionColor || 'text-grayscale--500'} fw-400`}>
                  Não foi possível encontrar nenhuma pergunta para: {searchText}
                </p>
              </div>
            )
        }
      </div>
    </div>
  )
}

FAQ.defaultProps = {
  inputPlaceholder: 'Pesquisar',
  className: '',
  chevronColor: '#ff7a00',
}

export default FAQ
